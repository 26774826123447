import React, { useEffect, useState, useRef } from "react"
import { linear } from "everpolate"
import BackgroundImage from "gatsby-background-image"
import { TweenMax } from "gsap"
import { useStaticQuery, graphql } from "gatsby"

import { entryImg } from "../../pages/index"
import { officeImg } from "../../pages/office"
import { cabinetImg } from "../../pages/cabinet"
import { mapImg } from "../../pages/map"
import { paintingImg } from "../../pages/painting"
import { tableImg } from "../../pages/table"

import styles from "./loader.module.css"
import LoaderSvg from "../../svg/loader.svg"

const images = [entryImg, cabinetImg, mapImg, paintingImg, tableImg]
const total = images.length + 1

const Loader = () => {
  const onePart = 269 / total
  const [loaded, setLoaded] = useState(0)
  const [complete, setComplete] = useState(false)
  const mask = useRef(null)
  const backgroundRef = useRef(null)
  const progressBar = useRef(null)

  useEffect(() => {
    // disableBodyScroll()
    mask.current = document.getElementById("loader-bg")
    backgroundRef.current = document.getElementById("loader-bg-image")
    progressBar.current = document.getElementById("progress-bar")

    const indexToLoad = window.devicePixelRatio >= 2 ? 1 : 0

    loadImage(officeImg[indexToLoad])
    setTimeout(() => {
      images.forEach(image => {
        loadImage(image[indexToLoad])
      })
    }, 100)
  }, [])

  useEffect(() => {
    TweenMax.to(mask.current, 0.5, {
      x: onePart * loaded,
    })
    const percentage = Math.ceil((loaded / total) * 100) || 0
    const gray = linear(percentage, [0, 100], [100, 0])
    const blur = linear(percentage, [0, 100], [10, 0])
    TweenMax.to(backgroundRef.current, 0.5, {
      filter: `grayscale(${gray}%) blur(${blur}px)`,
      autoRound: false,
    })

    const shift = linear(percentage, [0, 100], [-100, 0])
    TweenMax.to(progressBar.current, 0.5, {
      x: `${shift}%`,
    })

    if (loaded === total) {
      setComplete(true)
    }
  }, [loaded])

  function loadImage(src) {
    const img = new Image()
    img.src = src
    img.addEventListener("load", () => {
      setLoaded(l => l + 1)
    })
  }

  const percentage = Math.ceil((loaded / total) * 100) || 0

  const {
    placeholderImage: {
      childImageSharp: { fluid: background },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "background/office_2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={`${styles.container} ${
        complete ? styles.containerClosed : ""
      }`}
    >
      <BackgroundImage
        id="loader-bg-image"
        className={styles.background}
        fluid={background}
      />
      <LoaderSvg />
      <div className={styles.progress}>
        <div id="progress-bar" className={styles.progressBar}></div>
      </div>
      <p className={styles.percentage}>{percentage} %</p>
    </div>
  )
}

export default Loader
