import React from "react"

import styles from "./vkScroll.module.css"
// import { Scrollbars } from "react-custom-scrollbars"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

const VKScroll = ({ children }) => {
  return (
    <div className={styles.container}>
      <PerfectScrollbar>{children}</PerfectScrollbar>
    </div>
  )
}

export default VKScroll
