import React, { useState, useEffect, useRef } from "react"
import { linear } from "everpolate"
import { TweenMax } from "gsap"
import { debounce, throttle } from "lodash-es"
import { Howl } from "howler"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { getLS, setLS } from "../../lib/storage"
import styles from "./background.module.css"
import utils from "../utils.module.css"
import Magnet from "../pointer/magnet"

const Background = ({
  children,
  src,
  enter,
  points,
  disableMove,
  pageName,
}) => {
  const wrapper = useRef(null)
  const content = useRef(null)
  const pos = useRef(null)
  const [inited, setInited] = useState(false)
  const [resized, setResized] = useState(0)

  const trottleAnimate = useRef(throttle(animate, 20)).current
  const trottleAnimateGyro = useRef(throttle(animateGyro, 20)).current
  const debHandleResize = useRef(debounce(handleResize, 100)).current

  const windowH = useRef(null)
  const windowW = useRef(null)
  const heightDiffHalf = useRef(null)
  const widthDiffHalf = useRef(null)

  useEffect(() => {
    if (!inited || enter || disableMove) {
      return
    }

    windowH.current = window.innerHeight
    windowW.current = window.innerWidth

    heightDiffHalf.current = Math.round(
      Math.abs(wrapper.current.offsetHeight - content.current.offsetHeight) / 2
    )
    widthDiffHalf.current = Math.round(
      Math.abs(wrapper.current.offsetWidth - content.current.offsetWidth) / 2
    )

    if (widthDiffHalf.current <= 30) {
      widthDiffHalf.current = 0
    }
    if (heightDiffHalf.current <= 30) {
      heightDiffHalf.current = 0
    }

    const mq = window.matchMedia("(max-aspect-ratio: 100/85)")

    if (mq.matches) {
      try {
        window.addEventListener("deviceorientation", trottleAnimateGyro)
      } catch (error) {
        console.error(error)
      }
    } else {
      window.addEventListener("mousemove", trottleAnimate)
    }

    window.addEventListener("resize", debHandleResize)

    if (pageName) {
      const scrollPos = getLS(`scroll_${pageName}`, 0)

      if (scrollPos) {
        pos.current.scrollTo(+scrollPos, 0)
      }
    }

    return () => {
      try {
        window.removeEventListener("deviceorientation", trottleAnimateGyro)
      } catch (error) {
        console.error(error)
      }
      window.removeEventListener("mousemove", trottleAnimate)

      TweenMax.to(pos.current, 0.5, {
        x: 0,
        y: 0,
      })

      window.removeEventListener("resize", debHandleResize)
    }
  }, [inited, resized])

  function animate(e) {
    const x = Math.round(
      linear(
        e.clientX,
        [0, windowW.current],
        [widthDiffHalf.current, -widthDiffHalf.current]
      )
    )
    const y = Math.round(
      linear(
        e.clientY,
        [0, windowH.current],
        [heightDiffHalf.current, -heightDiffHalf.current]
      )
    )

    TweenMax.to(pos.current, 1, {
      x,
      y,
    })
  }

  function animateGyro(e) {
    const xMax = 20
    const gamma = Math.min(Math.max(e.gamma, -xMax), xMax)

    const x = Math.round(linear(gamma, [xMax, -xMax], [30, -30]))

    TweenMax.to(pos.current, 0.5, {
      x,
    })
  }

  function handleResize() {
    setResized(v => v + 1)
  }

  function handleScroll(e) {
    if (!pageName) {
      return
    }
    setLS(`scroll_${pageName}`, e.target.scrollLeft)
  }

  return (
    <div className={styles.wrapper}>
      <div
        ref={wrapper}
        className={`${styles.backgroundWrap} ${
          inited ? "" : styles.backgroundWindowHidden
        }`}
      >
        <div
          ref={pos}
          className={`${styles.backgroundPos} ${
            enter ? "" : ` ${styles.defaultBackgroundPos}`
          }`}
          onScroll={handleScroll}
        >
          <div
            ref={content}
            className={`${styles.backgroundContent}${
              enter ? ` ${styles.enterWrap}` : ` ${styles.defaultWrap}`
            }`}
          >
            <img
              className={styles.backgroundImage}
              src={src[0]}
              alt="Дверь в кабинет"
              srcSet={`${src[1]} 2x`}
              width="2000"
              height="1125"
              onLoad={() => {
                setInited(true)
              }}
              onContextMenu={e => {
                e.preventDefault()
              }}
            />
            {!!points &&
              points.map(({ href, position, sound }) => (
                <Magnet
                  key={href}
                  className={styles.pointWrap}
                  innerClassName={styles.magnetInner}
                  style={{
                    left: position[0] + "%",
                    top: position[1] + "%",
                  }}
                  multiply={1.3}
                >
                  <AniLink
                    paintDrip
                    hex="#191919"
                    duration={0.45}
                    to={href}
                    className={`${styles.point} js-hover js-stick`}
                    onClick={() => {
                      const soundHowl = new Howl({
                        src: [sound],
                      })
                      soundHowl.play()
                    }}
                  >
                    point
                  </AniLink>
                </Magnet>
              ))}
          </div>
        </div>
      </div>
      {!!children && (
        <div className={`${styles.content} ${utils.maxWidth}`}>{children}</div>
      )}
    </div>
  )
}

export default Background
