import React from "react"

import SEO from "../components/layout/seo"
import Background from "../components/background/background"
import Inner from "../components/content/inner"

import src from "../images/background/table.jpg"
import src2x from "../images/background/table_2x.jpg"

export const tableImg = [src, src2x]

const points = [
  {
    href: "/item/table-book/",
    position: [15, 45],
  },
  {
    href: "/item/table-handgun/",
    position: [35, 20],
  },
  {
    href: "/item/table-private/",
    position: [70, 64],
  },
  {
    href: "/item/table-key/",
    position: [32, 41],
  },
]

const TablePage = () => (
  <>
    <SEO
      title="Письменный стол"
      description="Обстановка на столе великого оружейника весьма аскетична: здесь нет ничего лишнего - только самые ценные вещи для Михаила Тимофеевича"
    />
    <Background src={tableImg} points={points} pageName="table">
      <Inner
        backLink="/office/"
        title="Письменный стол"
        description="Обстановка на столе великого оружейника весьма аскетична: здесь нет ничего лишнего - только самые ценные вещи для Михаила Тимофеевича"
      />
    </Background>
  </>
)

export default TablePage
