import React from "react"

import SEO from "../components/layout/seo"
import Background from "../components/background/background"
import Inner from "../components/content/inner"

import src from "../images/background/office.jpg"
import src2x from "../images/background/office_2x.jpg"

export const officeImg = [src, src2x]

const points = [
  {
    href: "/table/",
    position: [43, 70],
  },
  {
    href: "/painting/",
    position: [25, 20],
  },
  {
    href: "/item/design/",
    position: [33, 43],
  },
  {
    href: "/map/",
    position: [56, 26],
  },
  {
    href: "/cabinet/",
    position: [78, 45],
  },
]

const OfficePage = () => (
  <>
    <SEO
      title="Кабинет"
      description="Последние годы Михаил Тимофеевич работал именно здесь - в одном из кабинетов Конструкторско-технологического центра на территории завода «Ижмаш»."
    />
    <Background src={officeImg} points={points} pageName="office">
      <Inner
        backLink="/"
        title="Рабочий кабинет"
        description="Последние годы Михаил Тимофеевич работал именно здесь - в одном из кабинетов Конструкторско-технологического центра на территории завода «Ижмаш»."
      />
    </Background>
  </>
)

export default OfficePage
