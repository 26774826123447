import React from "react"

import SEO from "../components/layout/seo"
import Background from "../components/background/background"
import Inner from "../components/content/inner"

import src from "../images/background/cabinet.jpg"
import src2x from "../images/background/cabinet_2x.jpg"

export const cabinetImg = [src, src2x]

const points = [
  {
    href: "/item/pk/",
    position: [47, 71],
  },
  {
    href: "/item/kpp/",
    position: [25, 20],
  },
  {
    href: "/item/ak-47/",
    position: [33, 43],
  },
  {
    href: "/item/pp-1942/",
    position: [64, 21],
  },
  {
    href: "/item/ak-74/",
    position: [76, 45],
  },
]

const CabinetPage = () => (
  <>
    <SEO
      title="Шкаф с оружием"
      description="Посмотрите как на самые известные, так и редкие, опытные образцы оружия, созданные легендарным конструктором "
    />
    <Background src={cabinetImg} points={points} pageName="cabinet">
      <Inner
        backLink="/office/"
        title="Шкаф с оружием"
        description="Посмотрите как на самые известные, так и редкие, опытные образцы оружия, созданные легендарным конструктором "
      />
    </Background>
  </>
)

export default CabinetPage
