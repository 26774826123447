import React, { useEffect } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import styles from "./inner.module.css"
import utils from "../utils.module.css"

const Inner = ({ backLink, title, description }) => {
  useEffect(() => {
    if (window.vkInited) {
      window.VK.Widgets.Like("vk_like", { type: "button" })
    }
  }, [])

  return (
    <div className={styles.wrap}>
      <AniLink
        paintDrip
        hex="#191919"
        duration={0.45}
        className={`${utils.button} ${utils.buttonWhite} js-hover`}
        to={backLink}
      >
        ← Назад
      </AniLink>
      {!!title && <h1 className={styles.title}>{title}</h1>}
      {!!description && <p>{description}</p>}
      <div id="vk_like"></div>
    </div>
  )
}

export default Inner
