import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"
import { Howl } from "howler"

import styles from "./entry.module.css"
import utils from "../utils.module.css"
import knock from "../../sounds/knok.mp3"
import open from "../../sounds/open.mp3"

const knockHowl = new Howl({
  src: [knock],
})
const openHowl = new Howl({
  src: [open],
})

const Entry = () => {
  const [knocked, setKnocked] = useState(false)

  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash100 },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "kalash-100.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={styles.wrap}>
      <Img
        className={styles.image}
        fluid={kalash100}
        alt="Михаил Тимофеевич Калашников"
        style={{ width: 220 }}
      />
      <p>
        10&nbsp;ноября 2019 года исполняется 100 лет со&nbsp;дня рождения
        великого советского и&nbsp;российского оружейника Михаила Калашникова.
        До&nbsp;этой круглой даты он&nbsp;не&nbsp;дожил всего несколько лет, тем
        не&nbsp;менее наследие конструктора до&nbsp;сих пор играет и&nbsp;еще
        долго будет играть ключевую роль в&nbsp;современном российском ОПК.
      </p>
      <p>
        В&nbsp;память об&nbsp;этом великом человеке мы&nbsp;решили воссоздать
        модель его кабинета, чтобы рассказать о&nbsp;разных сторонах его
        личности: методах работы, образовании, военной службе, друзьях, коллегах
        и&nbsp;многом другом, из&nbsp;чего состоял мир Михаила Тимофеевича
        Калашникова.
      </p>
      {/* <h2 className={styles.title}>
        Перед тем как войти, постучитесь, возможно Михаил Тимофеевич сейчас
        занят.
      </h2> */}
      {knocked ? (
        <AniLink
          paintDrip
          hex="#191919"
          duration={0.45}
          className={`${styles.btn} ${utils.buttonFull} ${utils.button} js-hover`}
          to="/office"
          onClick={() => {
            openHowl.play()
          }}
        >
          Войти в кабинет →
        </AniLink>
      ) : (
        <button
          className={`${styles.btn} ${utils.buttonFull} ${utils.button} js-hover`}
          type="button"
          onClick={() => {
            knockHowl.play()
            setKnocked(true)
          }}
        >
          Постучаться в дверь →
        </button>
      )}
    </div>
  )
}

export default Entry
