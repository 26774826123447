import React, { PureComponent, createRef } from "react"

import { TweenLite, Power2 } from "gsap"

export default class Magnet extends PureComponent {
  box
  move
  boxStyler
  boxXY
  activeAction
  pointerTracker

  constructor(props) {
    super(props)
    this.box = createRef()
    this.move = createRef()
  }

  componentDidMount() {
    const { multiply = 1 } = this.props

    const box = this.box.current
    const move = this.move.current

    box.addEventListener("mouseenter", e => {
      this.parallaxIt(e, box, 25 * multiply)
    })
    box.addEventListener("mousemove", e => {
      this.parallaxIt(e, box, 25 * multiply)
    })
    box.addEventListener("mouseleave", e => {
      this.parallaxIt(e, box, 25 * multiply)
      TweenLite.to(move, 0.2 * multiply, {
        x: 0,
        y: 0,
      })
    })
  }

  parallaxIt = (e, element, modef) => {
    const { multiply = 1 } = this.props

    const elementBounds = element.getBoundingClientRect()
    const xPos = e.pageX - elementBounds.left
    const yPos = e.pageY - elementBounds.top - window.scrollY

    TweenLite.to(this.move.current, 0.3 * multiply, {
      x: ((xPos - elementBounds.width / 2) / elementBounds.width) * modef,
      y: ((yPos - elementBounds.height / 2) / elementBounds.height) * modef,
      ease: Power2.easeOut,
    })
  }

  render() {
    const { children, className, innerClassName, style } = this.props

    return (
      <div
        ref={this.box}
        className={`${className || ""} js-hover"`}
        style={style}
      >
        <div ref={this.move} className={innerClassName}>
          {children}
        </div>
      </div>
    )
  }
}
