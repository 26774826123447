import React from "react"
import styles from "./footer.module.css"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      © 2019 Группа компаний «Калашников»
    </footer>
  )
}

export default Footer
