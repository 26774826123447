import React, { PureComponent, createRef } from "react"
import { TweenMax } from "gsap"
import { throttle } from "lodash-es"
import { closest } from "../../lib/utils"

import styles from "./pointer.module.css"

class CustomPointer extends PureComponent {
  cursor
  posX = 0
  posY = 0
  mouseX = 0
  mouseY = 0

  constructor(props) {
    super(props)
    this.cursor = createRef()
    this.state = {
      hover: false,
      noPointer: false,
    }

    this.handleHover = throttle(this.handleHover, 100).bind(this)
  }

  handleMove = e => {
    this.mouseX = e.clientX
    this.mouseY = e.clientY
  }

  handleHover = e => {
    const hasHoverParent = !!closest(e.target, ".js-hover")
    const hasNoPointer = !!closest(e.target, ".no-pointer")
    this.setState({
      hover: hasHoverParent,
      noPointer: hasNoPointer,
    })
  }

  watchMouseMove = () => {
    TweenMax.to({}, 0.016, {
      repeat: -1,
      onRepeat: () => {
        this.posX += (this.mouseX - this.posX) / 5
        this.posY += (this.mouseY - this.posY) / 5

        TweenMax.set(this.cursor.current, {
          css: {
            transform: `translate3d(${this.posX - 8}px,${this.posY - 8}px,0)`,
          },
        })
      },
    })

    window.addEventListener("mousemove", this.handleMove)
    window.addEventListener("mousemove", this.handleHover)
  }

  componentDidMount() {
    this.watchMouseMove()
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.handleMove)
    window.removeEventListener("mousemove", this.handleHover)
  }

  render() {
    const { hover, noPointer } = this.state

    return (
      <div
        className={`${styles.container} ${
          noPointer ? ` ${styles.noPointer}` : ""
        }`}
      >
        <div ref={this.cursor} className={styles.wrap}>
          <div
            className={`${styles.circle}${hover ? ` ${styles.hover}` : ""}`}
          />
        </div>
      </div>
    )
  }
}

export default CustomPointer
