export function getLS(key, fallback) {
  try {
    const stringRes = localStorage.getItem(key)
    if (stringRes) {
      const parsedRes = JSON.parse(stringRes)
      return parsedRes
    } else {
      return fallback
    }
  } catch (error) {
    return fallback
  }
}

export function setLS(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(error)
  }
}
