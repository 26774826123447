import React from "react"

// import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Background from "../components/background/background"
import Entry from "../components/content/entry"
import Footer from "../components/layout/footer"

import src from "../images/background/door.jpg"
import src2x from "../images/background/door_2x.jpg"
import open from "../sounds/open.mp3"
// import srcSmall from "../images/background/door-sm.jpg"
// import src2xSmall from "../images/background/door-sm_2x.jpg"

export const entryImg = [src, src2x]
// export const smallImg = [srcSmall, src2xSmall]
// export const entryImg = ["background/door.jpg", "background/door_2x.jpg"]

const points = [
  {
    href: "/office/",
    position: [73.3, 39.5],
    sound: open,
  },
]

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Калашников100"
        description="«Калашников100» — мультимедийный спецпроект Калашников.Media, посвященный 100-летию со дня рождения великого конструктора Михаила Калашникова."
      />
      <Background enter src={entryImg} points={points} pageName="main">
        <>
          <Entry />
          <Footer />
        </>
      </Background>
    </>
  )
}

export default IndexPage
