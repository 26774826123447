// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cabinet-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/cabinet.js" /* webpackChunkName: "component---src-pages-cabinet-js" */),
  "component---src-pages-index-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-office-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/office.js" /* webpackChunkName: "component---src-pages-office-js" */),
  "component---src-pages-painting-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/painting.js" /* webpackChunkName: "component---src-pages-painting-js" */),
  "component---src-pages-table-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/table.js" /* webpackChunkName: "component---src-pages-table-js" */),
  "component---src-pages-item-ak-47-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/ak-47.js" /* webpackChunkName: "component---src-pages-item-ak-47-js" */),
  "component---src-pages-item-ak-74-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/ak-74.js" /* webpackChunkName: "component---src-pages-item-ak-74-js" */),
  "component---src-pages-item-design-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/design.js" /* webpackChunkName: "component---src-pages-item-design-js" */),
  "component---src-pages-item-kpp-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/kpp.js" /* webpackChunkName: "component---src-pages-item-kpp-js" */),
  "component---src-pages-item-map-africa-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/map-africa.js" /* webpackChunkName: "component---src-pages-item-map-africa-js" */),
  "component---src-pages-item-map-cuba-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/map-cuba.js" /* webpackChunkName: "component---src-pages-item-map-cuba-js" */),
  "component---src-pages-item-map-france-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/map-france.js" /* webpackChunkName: "component---src-pages-item-map-france-js" */),
  "component---src-pages-item-map-india-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/map-india.js" /* webpackChunkName: "component---src-pages-item-map-india-js" */),
  "component---src-pages-item-map-indonesia-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/map-indonesia.js" /* webpackChunkName: "component---src-pages-item-map-indonesia-js" */),
  "component---src-pages-item-map-oae-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/map-oae.js" /* webpackChunkName: "component---src-pages-item-map-oae-js" */),
  "component---src-pages-item-map-ukraine-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/map-ukraine.js" /* webpackChunkName: "component---src-pages-item-map-ukraine-js" */),
  "component---src-pages-item-map-venezuela-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/map-venezuela.js" /* webpackChunkName: "component---src-pages-item-map-venezuela-js" */),
  "component---src-pages-item-painting-church-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/painting- church.js" /* webpackChunkName: "component---src-pages-item-painting-church-js" */),
  "component---src-pages-item-painting-museum-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/painting-museum.js" /* webpackChunkName: "component---src-pages-item-painting-museum-js" */),
  "component---src-pages-item-painting-school-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/painting-school.js" /* webpackChunkName: "component---src-pages-item-painting-school-js" */),
  "component---src-pages-item-pk-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/pk.js" /* webpackChunkName: "component---src-pages-item-pk-js" */),
  "component---src-pages-item-pp-1942-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/pp-1942.js" /* webpackChunkName: "component---src-pages-item-pp-1942-js" */),
  "component---src-pages-item-table-book-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/table-book.js" /* webpackChunkName: "component---src-pages-item-table-book-js" */),
  "component---src-pages-item-table-drawing-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/table-drawing.js" /* webpackChunkName: "component---src-pages-item-table-drawing-js" */),
  "component---src-pages-item-table-handgun-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/table-handgun.js" /* webpackChunkName: "component---src-pages-item-table-handgun-js" */),
  "component---src-pages-item-table-key-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/table-key.js" /* webpackChunkName: "component---src-pages-item-table-key-js" */),
  "component---src-pages-item-table-private-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/table-private.js" /* webpackChunkName: "component---src-pages-item-table-private-js" */),
  "component---src-pages-item-table-retro-js": () => import("/Users/butyavka/projects/kalash-office/src/pages/item/table-retro.js" /* webpackChunkName: "component---src-pages-item-table-retro-js" */)
}

