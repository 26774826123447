import React from "react"

import SEO from "../components/layout/seo"
import Background from "../components/background/background"
import Inner from "../components/content/inner"

import src from "../images/background/painting.jpg"
import src2x from "../images/background/painting_2x.jpg"

export const paintingImg = [src, src2x]

const points = [
  {
    href: "/item/painting- church/",
    position: [33, 71],
  },
  {
    href: "/item/painting-school/",
    position: [71, 62],
  },
  {
    href: "/item/painting-museum/",
    position: [50, 50],
  },
]

const TablePage = () => (
  <>
    <SEO
      title="Картина «Курья»"
      description="Родное село в Алтайском крае, преобразившееся благодаря Михаилу Тимофеевичу, хранит память о своем выдающемся уроженце"
    />
    <Background src={paintingImg} points={points} pageName="painting">
      <Inner
        backLink="/office/"
        title="Картина «Курья»"
        description="Родное село в Алтайском крае, преобразившееся благодаря Михаилу Тимофеевичу, хранит память о своем выдающемся уроженце"
      />
    </Background>
  </>
)

export default TablePage
