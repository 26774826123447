import React from "react"

import SEO from "../components/layout/seo"
import Background from "../components/background/background"
import Inner from "../components/content/inner"

import src from "../images/background/map.jpg"
import src2x from "../images/background/map_2x.jpg"

export const mapImg = [src, src2x]

const points = [
  {
    href: "/item/map-france/",
    position: [43, 46],
  },
  {
    href: "/item/map-cuba/",
    position: [23, 65],
  },
  {
    href: "/item/map-oae/",
    position: [57, 52],
  },
  {
    href: "/item/map-ukraine/",
    position: [50, 41],
  },
  {
    href: "/item/map-india/",
    position: [69, 50],
  },
  {
    href: "/item/map-africa/",
    position: [47, 95],
  },
  {
    href: "/item/map-indonesia/",
    position: [97, 62],
  },
  {
    href: "/item/map-venezuela/",
    position: [23, 75],
  },
]

const MapPage = () => (
  <>
    <SEO
      title="Карта"
      description="Самые интересные и захватывающие истории из заграничных поездок Калашникова. Куба, Венесуэла, Индия и другие страны, в которых бывал создатель АК"
    />
    <Background src={mapImg} points={points} pageName="map">
      <Inner
        backLink="/office/"
        title="Карта командировок"
        description="Самые интересные и захватывающие истории из заграничных поездок Калашникова. Куба, Венесуэла, Индия и другие страны, в которых бывал создатель АК"
      />
    </Background>
  </>
)

export default MapPage
