import React, { useState, useEffect } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import utils from "../utils.module.css"
import styles from "./header.module.css"
import Fullscreen from "../../svg/fullscreen.svg"
import FullscreenExit from "../../svg/fullscreen-exit.svg"
import KalashMedia from "../../svg/kalash.svg"
import Group from "../../svg/group.svg"
import Plus from "../../svg/plus.svg"
import Add from "../../svg/add.svg"

const Header = () => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [vkApp, setVkApp] = useState(false)

  useEffect(() => {
    // Check if VK is inited
    if (window.vkInited) {
      setVkApp(true)
    }

    document.addEventListener("fullscreenchange", handleFullscreenChange)

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange)
    }
  }, [])

  function handleFullscreenChange() {
    setIsFullscreen(!!document.fullscreenElement)
  }

  return (
    <header
      className={`${styles.header} ${utils.maxWidth}${
        isFullscreen ? ` ${styles.fullscreenHeader}` : ""
      }`}
    >
      <div
        className={`${styles.content} ${vkApp ? ` ${styles.contentVk}` : ""}`}
      >
        <AniLink
          paintDrip
          hex="#191919"
          duration={0.45}
          to="/"
          className={`js-hover ${styles.mediaLink}`}
        >
          <KalashMedia className={styles.media} />
        </AniLink>

        <a
          className={styles.vkButton}
          href="https://vk.com/kalashnikovgrp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Group />
          <span>Сообщество</span>
        </a>

        <button
          className={styles.vkButton}
          onClick={() => {
            try {
              window.VK.callMethod("showInviteBox")
            } catch (error) {}
          }}
          type="button"
        >
          <Plus />
          <span>Пригласить друзей</span>
        </button>

        <button
          className={styles.vkButton}
          onClick={() => {
            try {
              window.VK.callMethod("showSettingsBox", 256)
            } catch (error) {}
          }}
          type="button"
        >
          <Add />
          <span>Добавить в меню</span>
        </button>

        <div className={styles.aside}>
          <AniLink
            paintDrip
            hex="#191919"
            duration={0.45}
            to="/about/"
            className={`js-hover ${styles.aboutLink}`}
          >
            О проекте
          </AniLink>
          <button
            className={styles.fullscreen}
            type="button"
            onClick={() => {
              if (document.fullscreenElement) {
                document.exitFullscreen()
              } else {
                document.documentElement.requestFullscreen()
              }
            }}
          >
            {isFullscreen ? (
              <FullscreenExit className={styles.fullscreenIcon} />
            ) : (
              <Fullscreen className={styles.fullscreenIcon} />
            )}
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
