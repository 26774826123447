module.exports = [{
      plugin: require('/Users/butyavka/projects/kalash-office/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kalashnikov Office","short_name":"Office","start_url":"/","background_color":"#000000","theme_color":"#EB0029","display":"minimal-ui","icon":"src/images/fav.png"},
    },{
      plugin: require('/Users/butyavka/projects/kalash-office/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/Users/butyavka/projects/kalash-office/src/components/layout/layout.js"},
    },{
      plugin: require('/Users/butyavka/projects/kalash-office/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
