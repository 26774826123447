/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import React from "react"
// import Layout from "./src/components/layout/layout"

// export const wrapPageElement = ({ element, props }) => {
//   return <Layout {...props}>{element}</Layout>
// }

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add("user-is-tabbing")

    window.removeEventListener("keydown", handleFirstTab)
    window.addEventListener("mousedown", handleMouseDownOnce)
  }
}

function handleMouseDownOnce() {
  document.body.classList.remove("user-is-tabbing")

  window.removeEventListener("mousedown", handleMouseDownOnce)
  window.addEventListener("keydown", handleFirstTab)
}

window.addEventListener("keydown", handleFirstTab)

function updateVh() {
  try {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  } catch (error) {
    console.error(error)
  }
}

updateVh()
window.addEventListener("resize", updateVh)

// Image.prototype.load = function(url) {
//   var thisImg = this
//   var xmlHTTP = new XMLHttpRequest()
//   xmlHTTP.open("GET", url, true)
//   xmlHTTP.responseType = "arraybuffer"
//   xmlHTTP.onload = function(e) {
//     var blob = new Blob([this.response])
//     thisImg.src = window.URL.createObjectURL(blob)
//   }
//   xmlHTTP.onprogress = function(e) {
//     thisImg.completedPercentage = parseInt((e.loaded / e.total) * 100)
//   }
//   xmlHTTP.onloadstart = function() {
//     thisImg.completedPercentage = 0
//   }
//   xmlHTTP.send()
// }

// Image.prototype.completedPercentage = 0
